'use client'

import * as Popover from '@radix-ui/react-popover'
import React, { Fragment, type PropsWithChildren } from 'react'

import { availableLocales, languageNames } from '@/lib/i18n/config'
import { Link, usePathname } from '@/lib/i18n/navigation'

import Button from '../Button'
import styles from './styles.module.css'

interface InlineLanguageSwitchProps {
  currentLocale: string
  title?: string
}

const InlineLanguageSwitch = ({
  currentLocale,
  title,
  children
}: PropsWithChildren<InlineLanguageSwitchProps>): JSX.Element => {
  const pathname = usePathname()

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        {React.Children.toArray(children).length !== 0 ? (
          children
        ) : (
          <Button
            appearance="plain"
            buttonType="icon"
            data-test="LanguageSwitch"
            icon="Globe"
            iconGradientHover
            size={24}
          >
            {title}
          </Button>
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="center" alignOffset={-12} className={styles.content} side="top" sideOffset={8}>
          {availableLocales.map(locale => (
            <Fragment key={locale}>
              {locale === currentLocale ? (
                <span
                  className="cursor-default px-16 py-8 text-b14 text-white outline-none hover:text-white"
                  data-test={`LanguageButton-${locale}`}
                >
                  {languageNames[locale]}
                </span>
              ) : (
                <Link
                  className="px-16 py-8 text-b14 text-greyblue-40 outline-none hover:text-white"
                  data-test={`LanguageButton-${locale}`}
                  href={pathname ?? ''}
                  locale={locale}
                >
                  {languageNames[locale]}
                </Link>
              )}
            </Fragment>
          ))}

          <Popover.Arrow asChild>
            <span className={styles.arrow} />
          </Popover.Arrow>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default InlineLanguageSwitch
